import './App.css';
import main_classes from '../src/pages/Main/Main.module.scss'

import { useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { decryptData } from './utils/helper_functions'


// unauthenticaed pages
import Main from './pages/Main/Main'

// admin pages and components
import AdminDashboard from './pages/AdminDashboard/AdminDashboard'
import AdminLogin from './pages/AdminLogin/AdminLogin'

// common pages
import ResetPassword from './pages/ResetPassword/ResetPassword'

// external components
import { uiActions } from './store/ui-slice';
import { userActions } from './store/user-slice';

import { GooSpinner } from 'react-spinners-kit';

// import TagManager from 'react-gtm-module'
// const tagManagerArgs = {
//   gtmId: 'GTM-M8463SJ'
// }
// TagManager.initialize(tagManagerArgs)



function App(){
  const dispatch = useDispatch()
  // const initPath = useRef(window.location.pathname)
  const PATH_NAME = window.location.pathname

  const aes_salt_key = process.env.REACT_APP_AES_SALT
  const API_URL = process.env.REACT_APP_API_URL
  const isAdminLoggedIn = useSelector(state => state.ui.isAdminLoggedIn)

  // loading states
  const [isLoading, setIsLoading] = useState(true)


  // ############################ useEffect section ############################
  const verify_admin_access_token = async(access_token, admin_user, admin_role) => {
      console.log("verifying access token for admin user :", admin_user, admin_role)
      const res = await fetch(`${API_URL}/api/v2/adminlogin`, {
        method:'GET',
        headers:{
          'adminEmail':admin_user,
          'adminRole':admin_role,
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${access_token}`,
        }
      })
      const res_status = res.status
      const data = await res.json()
      console.log('res in verifying access token :', res_status, data)
      if(res_status === 200){
        dispatch(uiActions.setIsAdminLoggedIn(true))
        dispatch(userActions.setUser(admin_user))
        if(admin_role==="ADMIN"){
            dispatch(userActions.setIsAdmin(true))
        }else{
            dispatch(userActions.setIsModerator(true))
        }
        setTimeout(() => {setIsLoading(false)}, 1000);
      }else{
        dispatch(uiActions.setIsAdminLoggedIn(false))
        setTimeout(() => {setIsLoading(false)}, 1000);
      }
  }
  

  // check if admin is logged in and had valid credentials
  useEffect(() => {
    if(PATH_NAME.includes('/admin/')){
      let admin_user = localStorage.getItem('prompt_admin_user')      
      let admin_role = localStorage.getItem('prompt_admin_role')
      const admin_access_token = decryptData(localStorage.getItem('prompt_admin_access_token', null), aes_salt_key)
      console.log("admin app.js useeffect is :", isAdminLoggedIn, PATH_NAME, PATH_NAME.includes('/admin/'))
      
      if(admin_user && admin_role && admin_access_token){
        // if admin user, role and access token found, then try to verify access token and set role, user to useractions
        verify_admin_access_token(admin_access_token, admin_user, admin_role)
      }else{
        // set isAdminLoggedIn as false
        dispatch(uiActions.setIsAdminLoggedIn(false))
        setTimeout(() => {setIsLoading(false)}, 1000);
      }
    }else{
      setTimeout(() => {setIsLoading(false)}, 1000);
    }
  },[isAdminLoggedIn])



  return(  
    <div className="app">  
      {isLoading?(
        <div className={main_classes.full_screen_center_center}>
          <div className={main_classes.main_spinner_div}>
              <GooSpinner/>
          </div>
        </div>
      ):(
        <Routes>
            {/* user and unauthenticated routes managed by Main.js */}
            <Route path='/*' element={<Main/>}/>

            {/* admin */}
            {isAdminLoggedIn?(
              <>
              <Route path='/admin/login' exact element={<Navigate to='/admin/edit'/>}/>
              <Route path='/admin/reset' exact element={<Navigate to='/admin/edit'/>}/>
              <Route path='/admin/home' exact element={<AdminDashboard/>}/>
              <Route path='/admin/edit' exact element={<AdminDashboard/>}/>
              <Route path='/admin/users' exact element={<AdminDashboard/>}/>
              <Route path='/admin/*' element={<Navigate to='/admin/edit'/>}/>
              </>
            ):(
              <>
              <Route path='/admin/login' exact element={<AdminLogin/>}/>
              <Route path='/admin/reset' exact element={<ResetPassword isAdmin/>}/>
              <Route path='/admin/*' element={<Navigate to='/admin/login'/>}/>
              </>
            )}
        </Routes>
      )}
    </div>
  );
}

export default App;